// Fonction principale pour détecter le BPM
export const detectBPM = async (audioBuffer) => {
    // Obtenir les données audio
    const channelData = audioBuffer.getChannelData(0); // Utiliser le premier canal
    const sampleRate = audioBuffer.sampleRate;
  
    // Paramètres pour la détection
    const minTempo = 90;  // BPM minimum à détecter
    const maxTempo = 180; // BPM maximum à détecter
    
    // Réduire la taille des données pour améliorer les performances
    const samples = downSampleBuffer(channelData, sampleRate);
    
    // Trouver les pics d'énergie
    const peaks = findPeaks(samples);
    
    // Analyser les intervalles entre les pics
    const intervals = analyzeIntervals(peaks, sampleRate);
    
    // Grouper les intervalles similaires
    const groups = groupIntervals(intervals, sampleRate);
    
    // Trouver le BPM le plus probable
    let bpm = findDominantBPM(groups, sampleRate);
    
    // Si aucun BPM n'est trouvé dans la plage attendue, retourner une valeur par défaut
    if (bpm < minTempo || bpm > maxTempo) {
      bpm = 120;
    }
    
    return Math.round(bpm);
  };
  
  // Réduire la taille du buffer pour améliorer les performances
  const downSampleBuffer = (buffer, sampleRate) => {
    const scale = 200; // Facteur de réduction
    const blockSize = Math.floor(buffer.length / scale);
    const reduced = [];
    
    for (let i = 0; i < scale; i++) {
      let sum = 0;
      for (let j = 0; j < blockSize; j++) {
        sum += Math.abs(buffer[i * blockSize + j]);
      }
      reduced.push(sum / blockSize);
    }
    
    return reduced;
  };
  
  // Trouver les pics d'énergie dans le signal
  const findPeaks = (data) => {
    const peaks = [];
    const threshold = 0.9;
    
    for (let i = 1; i < data.length - 1; i++) {
      if (data[i] > threshold) {
        if (data[i] > data[i-1] && data[i] > data[i+1]) {
          peaks.push(i);
        }
      }
    }
    
    return peaks;
  };
  
  // Analyser les intervalles entre les pics
  const analyzeIntervals = (peaks, sampleRate) => {
    const intervals = [];
    
    for (let i = 0; i < peaks.length - 1; i++) {
      intervals.push(peaks[i + 1] - peaks[i]);
    }
    
    return intervals;
  };
  
  // Grouper les intervalles similaires
  const groupIntervals = (intervals, sampleRate) => {
    const groups = {};
    const tolerance = 0.05; // 5% de tolérance
    
    intervals.forEach(interval => {
      let grouped = false;
      
      Object.keys(groups).forEach(key => {
        const avgInterval = parseFloat(key);
        if (Math.abs(interval - avgInterval) / avgInterval < tolerance) {
          groups[key].push(interval);
          grouped = true;
        }
      });
      
      if (!grouped) {
        groups[interval] = [interval];
      }
    });
    
    return groups;
  };
  
  // Trouver le BPM dominant
  const findDominantBPM = (groups, sampleRate) => {
    let maxCount = 0;
    let dominantInterval = 0;
    
    Object.entries(groups).forEach(([interval, values]) => {
      if (values.length > maxCount) {
        maxCount = values.length;
        dominantInterval = parseFloat(interval);
      }
    });
    
    // Convertir l'intervalle en BPM
    // Note: nous multiplions par un facteur car nous avons réduit l'échantillonnage
    const bpm = (60 * sampleRate) / (dominantInterval * 200);
    
    return bpm;
  };
  
  // Fonction utilitaire pour détecter les seuils de volume
  export const getVolumeLevel = (audioBuffer, offset, length) => {
    const data = audioBuffer.getChannelData(0);
    let sum = 0;
    
    for (let i = offset; i < offset + length; i++) {
      sum += Math.abs(data[i]);
    }
    
    return sum / length;
  };
  
  // Fonction pour appliquer un effet de battement
  export const applyBeatEffect = (audioContext, source, bpm) => {
    const gainNode = audioContext.createGain();
    const oscillator = audioContext.createOscillator();
    const beatLength = 60 / bpm;
    
    oscillator.frequency.value = 1 / beatLength;
    oscillator.connect(gainNode.gain);
    
    source.connect(gainNode);
    gainNode.connect(audioContext.destination);
    
    oscillator.start();
    
    return {
      gainNode,
      oscillator
    };
  };
  
  // Fonction pour synchroniser deux pistes
  export const syncTracks = (track1BPM, track2BPM, track2PlaybackRate) => {
    return (track1BPM / track2BPM) * track2PlaybackRate;
  };
  
  // Analyser le spectre de fréquences pour la visualisation
  export const analyzeFrequencies = (audioContext, source) => {
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    
    source.connect(analyser);
    
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    
    const getFrequencyData = () => {
      analyser.getByteFrequencyData(dataArray);
      return dataArray;
    };
    
    return {
      analyser,
      getFrequencyData,
      bufferLength
    };
  };