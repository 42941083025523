let API_BASE_URL;

if (process.env.NODE_ENV === 'production') {
    API_BASE_URL = window.location.origin;
} else {
    API_BASE_URL = 'http://localhost:5000';
}

console.log('API_BASE_URL initialized as:', API_BASE_URL);

export default API_BASE_URL;