import React, { useState, useEffect, useCallback } from 'react';
import { Chess } from 'chess.js';
import { Chessboard } from 'react-chessboard';

const ChessMiniApp = () => {
  const [game, setGame] = useState(new Chess());
  const [playerColor, setPlayerColor] = useState('white');
  const [gameLevel, setGameLevel] = useState(1);

  // Initialisation du jeu
  useEffect(() => {
    const randomColor = Math.random() < 0.5 ? 'white' : 'black';
    setPlayerColor(randomColor);
    if (randomColor === 'black') {
      setTimeout(makeAIMove, 500);
    }
  }, []);

  const evaluatePosition = useCallback((position) => {
    const pieceValues = {
      p: 100,
      n: 320,
      b: 330,
      r: 500,
      q: 900,
      k: 20000
    };

    let score = 0;
    const board = position.board();

    // Évaluation des pièces
    for (let i = 0; i < 8; i++) {
      for (let j = 0; j < 8; j++) {
        const piece = board[i][j];
        if (piece) {
          const baseValue = pieceValues[piece.type.toLowerCase()];
          score += baseValue * (piece.color === 'w' ? 1 : -1);
        }
      }
    }

    // Bonus pour la mobilité
    const moves = position.moves();
    score += moves.length * (position.turn() === 'w' ? 0.1 : -0.1);

    return score;
  }, []);

  const getAIMove = useCallback(() => {
    const possibleMoves = game.moves();
    if (possibleMoves.length === 0) return null;

    const evaluatedMoves = possibleMoves.map(move => {
      const gameCopy = new Chess(game.fen());
      gameCopy.move(move);
      return {
        move: move,
        score: evaluatePosition(gameCopy)
      };
    });

    evaluatedMoves.sort((a, b) => 
      game.turn() === 'w' ? b.score - a.score : a.score - b.score
    );

    // Sélectionner le coup selon le niveau
    switch (gameLevel) {
      case 1:
        return evaluatedMoves.slice(0, 3)[Math.floor(Math.random() * 3)].move;
      case 2:
        return evaluatedMoves.slice(0, 2)[Math.floor(Math.random() * 2)].move;
      case 3:
        return evaluatedMoves[0].move;
      default:
        return evaluatedMoves[0].move;
    }
  }, [game, gameLevel, evaluatePosition]);

  const makeAIMove = useCallback(() => {
    const move = getAIMove();
    if (move) {
      const newGame = new Chess(game.fen());
      newGame.move(move);
      setGame(newGame);
    }
  }, [game, getAIMove]);

  const onDrop = useCallback((sourceSquare, targetSquare) => {
    // Vérifier si c'est le tour du joueur
    if ((game.turn() === 'w' && playerColor === 'black') ||
        (game.turn() === 'b' && playerColor === 'white')) {
      return false;
    }

    const newGame = new Chess(game.fen());
    try {
      const move = newGame.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: 'q'
      });

      if (move === null) return false;

      setGame(newGame);
      setTimeout(makeAIMove, 300);
      return true;
    } catch {
      return false;
    }
  }, [game, playerColor, makeAIMove]);

  const resetGame = useCallback(() => {
    setGame(new Chess());
    if (playerColor === 'black') {
      setTimeout(makeAIMove, 500);
    }
  }, [playerColor, makeAIMove]);

  return (
    <div className="w-full max-w-2xl mx-auto p-4">
      <div className="w-full aspect-square max-w-[600px] mx-auto">
        <Chessboard
          id="BasicBoard"
          position={game.fen()}
          onPieceDrop={onDrop}
          boardOrientation={playerColor === 'white' ? 'white' : 'black'}
          customBoardStyle={{
            borderRadius: '4px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
          }}
        />
      </div>

      <div className="mt-4 text-center">
        <div className="flex flex-wrap justify-center items-center gap-2">
          {[1, 2, 3].map(level => (
            <button
              key={level}
              style={{
                backgroundColor: gameLevel === level ? '#1a365d' : '#e5e7eb',
                color: gameLevel === level ? 'white' : 'black'
              }}
              className={`px-4 py-1 rounded text-sm sm:text-base sm:px-6 sm:py-2 hover:bg-gray-300 transition-colors ${
                gameLevel === level ? 'shadow-md' : ''
              }`}
              onClick={() => setGameLevel(level)}
            >
              {level}
            </button>
          ))}
          <button
            onClick={resetGame}
            className="px-3 py-1 text-sm sm:text-base sm:px-4 sm:py-2 bg-green-500 hover:bg-green-600 text-white rounded"
          >
            Nouvelle partie
          </button>
        </div>

        <div className="text-sm sm:text-lg font-semibold mt-4">
          {game.isCheckmate() && "Échec et mat !"}
          {game.isDraw() && "Partie nulle !"}
          {game.isStalemate() && "Pat !"}
          {game.isThreefoldRepetition() && "Nulle par répétition !"}
          {game.isInsufficientMaterial() && "Nulle par insuffisance de matériel !"}
        </div>
      </div>
    </div>
  );
};

export default ChessMiniApp;