// src/components/IllustrationEditor.js

import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Stage, Layer, Image as KonvaImage, Rect, Transformer } from 'react-konva';
import useImage from 'use-image';
import './IllustrationEditor.css';

// Fonction utilitaire pour convertir les zones en CSV
const generateCSV = (zones) => {
  // Créer le contenu CSV avec une seule colonne
  const csvContent = [
    // En-tête
    'Zone Content',
    // Labels
    ...zones.map(zone => zone.label || ''),
    // Ligne vide de séparation
    '',
    // Textes
    ...zones.map(zone => zone.content?.text || '')
  ].join('\n');
  
  return csvContent;
};

// Fonction utilitaire pour analyser le CSV à une colonne
const parseCSV = (csvText) => {
  // Séparer les lignes en ignorant les lignes vides
  const lines = csvText.split('\n').filter(line => line.trim() !== '');
  
  // Enlever l'en-tête
  lines.shift(); // Enlève "Zone Content"
  
  // Trouver le point médian (où commence les textes)
  const middleIndex = Math.floor(lines.length / 2);
  
  // Séparer en labels et textes
  const labels = lines.slice(0, middleIndex);
  const texts = lines.slice(middleIndex);
  
  // Vérifier que nous avons le même nombre de labels et de textes
  if (labels.length !== texts.length) {
    alert('Le nombre de labels ne correspond pas au nombre de textes');
    return [];
  }

  // Créer le tableau de résultats
  return labels.map((label, index) => ({
    label: label.trim(),
    text: texts[index].trim()
  }));
};



function IllustrationEditor({ previewIllustration, zones, setZones, handleZoneClick }) {
  const [newZone, setNewZone] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [editedZone, setEditedZone] = useState({
    label: '',
    text: '',
    imageFile: null,
    audioFile: null,
    videoFile: null,
    textAudioFile: null,
    labelAudioFile: null,
    existingImage: null,
    existingImageUrl: null,
    existingAudio: null,
    existingVideo: null,
    existingTextAudio: null,
    existingLabelAudio: null,
    application: null,
    applicationType: '', // Pour stocker le type d'application (ex: 'chess')
  
  });

  const AVAILABLE_APPLICATIONS = [
    { id: 'chess', name: 'Jeu d\'échecs', defaultConfig: {} },
    { 
      id: 'dj', 
      name: 'DJ Mix', 
      defaultConfig: {
        track1: '/uploads/track1.mp3',
        track2: '/uploads/track2.mp3'
      } 
    }// Ajoutez ici d'autres applications
    // Par exemple :
    // { id: 'calculator', name: 'Calculatrice', defaultConfig: {} },
    // { id: 'quiz', name: 'Quiz', defaultConfig: { questions: [] } },
    // { id: 'memory', name: 'Memory', defaultConfig: { difficulty: 'easy' } },
  ];



  const stageRef = useRef(null);
  const transformerRef = useRef(null);

  // URL de base pour les médias
  const baseURL = 'http://167.99.220.81:5000';

  // Charger l'image de l'illustration
  const [image] = useImage(previewIllustration);
  const containerRef = useRef(null);
  const [stageSize, setStageSize] = useState({ width: 800, height: 600 });

  // Ajuster la taille du Stage en fonction de l'image et de la taille du conteneur
  useEffect(() => {
    const updateStageSize = () => {
      if (image && containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        const imageAspectRatio = image.width / image.height;

        let newWidth = containerWidth;
        let newHeight = containerWidth / imageAspectRatio;

        if (newHeight > containerHeight) {
          newHeight = containerHeight;
          newWidth = newHeight * imageAspectRatio;
        }

        setStageSize({ width: newWidth, height: newHeight });
      }
    };

    updateStageSize();
    window.addEventListener('resize', updateStageSize);
    return () => window.removeEventListener('resize', updateStageSize);
  }, [image]);

  // Gérer la création des zones
  const handleMouseDown = (e) => {
    if (!image) return;

    // Ignorer le clic sur une zone existante
    const clickedOnEmpty = e.target === e.target.getStage();
    if (!clickedOnEmpty) return;

    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    setNewZone({
      x: pointerPosition.x,
      y: pointerPosition.y,
      width: 0,
      height: 0,
      isDragging: true,
    });
  };

  const handleMouseMove = (e) => {
    if (!newZone || !newZone.isDragging) return;

    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    const width = pointerPosition.x - newZone.x;
    const height = pointerPosition.y - newZone.y;
    setNewZone({
      ...newZone,
      width,
      height,
    });
  };

  const handleMouseUp = () => {
    if (!newZone || !newZone.isDragging) return;

    if (Math.abs(newZone.width) > 10 && Math.abs(newZone.height) > 10) {
      const label = prompt('Entrez le label associé à cette zone:');
      if (label) {
        const scale = image.width / stageSize.width;
        const normalizedZone = {
          x: newZone.x * scale,
          y: newZone.y * scale,
          width: newZone.width * scale,
          height: newZone.height * scale,
          label: label,
          content: { text: label },
        };
        const updatedZones = [...zones, normalizedZone];
        setZones(updatedZones);
        setSelectedId(updatedZones.length - 1); // Sélectionner la nouvelle zone
      }
    }
    setNewZone(null);
  };

  // Gérer la sélection des zones
  useEffect(() => {
    if (transformerRef.current && selectedId !== null) {
      const stage = stageRef.current;
      if (stage) {
        const layer = stage.findOne('Layer');
        if (layer) {
          const rect = layer.findOne(`#zone-${selectedId}`);
          if (rect) {
            transformerRef.current.nodes([rect]);
            transformerRef.current.getLayer().batchDraw();
          }
        }
      }
    } else if (transformerRef.current) {
      transformerRef.current.nodes([]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selectedId, zones]);

  const handleSelect = (index) => {
    const zone = zones[index];
    console.log(`Zone sélectionnée (index: ${index}):`, zone); // Debug
    setSelectedId(index);
    if (handleZoneClick) {
      handleZoneClick(zone);
    }
    setEditedZone({
      label: zone.label || '',
      text: zone.content?.text || '',
      imageFile: null,
      audioFile: null,
      videoFile: null,
      textAudioFile: null,
      labelAudioFile: null,
      existingImage: zone.content?.image || null,
      existingImageUrl: zone.content?.image ? `${baseURL}${zone.content.image}` : null,
      existingAudio: zone.content?.audio || null,
      existingVideo: zone.content?.video || null,
      existingTextAudio: zone.content?.textAudio || null,
      existingLabelAudio: zone.content?.labelAudio || null,
      applicationType: zone.content?.application?.type || '',
      application: zone.content?.application || null,


    });
  };

  const handleEditedZoneChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      setEditedZone((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setEditedZone((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDeleteZone = () => {
    if (selectedId === null) return;
    const updatedZones = zones.filter((_, index) => index !== selectedId);
    setZones(updatedZones);
    setSelectedId(null);
    setEditedZone({
      label: '',
      text: '',
      imageFile: null,
      audioFile: null,
      videoFile: null,
      textAudioFile: null,
      labelAudioFile: null,
      existingImage: null,
      existingAudio: null,
      existingVideo: null,
      existingTextAudio: null,
      existingLabelAudio: null,
    });
  };

  const handleDeleteContent = (contentType) => () => {
    setEditedZone((prev) => ({
      ...prev,
      [`existing${capitalizeFirstLetter(contentType)}`]: null,
      [`${contentType}File`]: null,
    }));
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Gestion des transformations et déplacements
  const handleDragEnd = (e, index) => {
    const stage = stageRef.current;
    const scale = image.width / stageSize.width;

    const updatedZones = zones.map((zone, i) => {
      if (i === index) {
        return {
          ...zone,
          x: e.target.x() * scale,
          y: e.target.y() * scale,
        };
      }
      return zone;
    });

    setZones(updatedZones);
  };

  const handleTransformEnd = (e, index) => {
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // Réinitialiser l'échelle pour éviter les accumulations
    node.scaleX(1);
    node.scaleY(1);

    const stage = stageRef.current;
    const scale = image.width / stageSize.width;

    const updatedZones = zones.map((zone, i) => {
      if (i === index) {
        return {
          ...zone,
          x: node.x() * scale,
          y: node.y() * scale,
          width: node.width() * scaleX * scale,
          height: node.height() * scaleY * scale,
        };
      }
      return zone;
    });

    setZones(updatedZones);
  };


// Fonction utilitaire pour échapper les caractères spéciaux dans une chaîne
const escapeSpecialChars = (text) => {
  if (!text) return '';
  // Échapper les retours à la ligne et autres caractères spéciaux
  return text
    .replace(/\n/g, '[NL]')         // retours à la ligne
    .replace(/,/g, '[COMMA]')       // virgules
    .trim();
};

// Fonction pour déséchapper les caractères spéciaux
const unescapeSpecialChars = (text) => {
  if (!text) return '';
  return text
    .replace(/\[NL\]/g, '\n')       // restaurer les retours à la ligne
    .replace(/\[COMMA\]/g, ',')     // restaurer les virgules
    .trim();
};


// Fonction utilitaire pour convertir les zones en CSV à une colonne
const generateCSV = (zones) => {
  // Créer le contenu avec chaque élément sur une ligne, en échappant les caractères spéciaux
  const labelsSection = zones.map(zone => {
    const label = zone.label || '';
    return label.replace(/\n/g, '[NL]').replace(/,/g, '[COMMA]');
  }).join('\n');

  const textsSection = zones.map(zone => {
    const text = zone.content?.text || '';
    return text.replace(/\n/g, '[NL]').replace(/,/g, '[COMMA]');
  }).join('\n');

  return labelsSection + '\n\n' + textsSection;
};

// Fonction utilitaire pour analyser le CSV
const parseCSV = (csvText) => {
  try {
    // Diviser en sections
    const [labelSection, textSection] = csvText.trim().split(/\n\s*\n/);
    
    if (!labelSection || !textSection) {
      throw new Error('Format de fichier invalide');
    }

    // Pour les labels : une entrée par ligne
    const labels = labelSection
      .split(/\n/)
      .filter(label => label.trim() !== '');

    // Pour les textes : traiter les textes entre guillemets comme une seule entrée
    const texts = [];
    let currentText = '';
    let inQuotes = false;
    
    textSection.split('\n').forEach(line => {
      line = line.trim();
      
      // Si la ligne commence par un guillemet
      if (line.startsWith('"') && !inQuotes) {
        inQuotes = true;
        currentText = line.substring(1); // Enlever le guillemet de début
      }
      // Si la ligne finit par un guillemet
      else if (line.endsWith('"') && inQuotes) {
        inQuotes = false;
        currentText += '\n' + line.slice(0, -1); // Enlever le guillemet de fin
        texts.push(currentText.trim());
        currentText = '';
      }
      // Si on est entre guillemets
      else if (inQuotes) {
        currentText += '\n' + line;
      }
      // Ligne simple sans guillemets
      else if (line !== '') {
        texts.push(line);
      }
    });

    // Vérification de la correspondance
    if (labels.length !== texts.length) {
      throw new Error(`Le nombre de labels (${labels.length}) ne correspond pas au nombre de textes (${texts.length}). 
      Labels: ${JSON.stringify(labels)}
      Textes: ${JSON.stringify(texts)}`);
    }

    // Construction du résultat
    return labels.map((label, index) => ({
      label,
      text: texts[index]
    }));

  } catch (error) {
    console.error('Contenu CSV:', csvText);
    console.error('Erreur complète:', error);
    alert(error.message);
    return null;
  }
};

// Ajoutez ces fonctions avec les autres gestionnaires d'événements du composant
const handleDownloadCSV = () => {
  const csvContent = generateCSV(zones);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'zones.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleCSVUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = parseCSV(e.target.result);
      
      if (csvData) {
        // Mise à jour des zones existantes avec les nouvelles données
        const updatedZones = zones.map((zone, index) => {
          if (index < csvData.length) {
            return {
              ...zone,
              label: csvData[index].label,
              content: {
                ...zone.content,
                text: csvData[index].text
              }
            };
          }
          return zone;
        });
        
        console.log('Zones mises à jour:', updatedZones); // Pour le debug
        setZones(updatedZones); // Ceci mettra à jour à la fois les zones locales et le state du parent
      }
    };
    reader.readAsText(file);
  }
};

// Fonctions pour Label Audio
const generateLabelAudioCSV = (zones) => {
  const header = "Zone Label,Label Audio Path";
  const rows = zones.map(zone => {
    const label = zone.label?.replace(/,/g, '[COMMA]') || '';
    const audioPath = zone.content?.labelAudio || '';
    return `${label},${audioPath}`;
  }).join('\n');
  return `${header}\n${rows}`;
};

const handleDownloadLabelAudioCSV = () => {
  const csvContent = generateLabelAudioCSV(zones);
  const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'label_audio_paths.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleLabelAudioCSVUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      const lines = content.split('\n');
      lines.shift(); // Enlever l'en-tête
      
      // On ne prend que les chemins audio (2ème colonne)
      const audioPaths = lines.map(line => {
        const columns = line.split(',');
        return columns[1]?.trim() || null;
      });
      
      const updatedZones = zones.map((zone, index) => ({
        ...zone,
        content: {
          ...zone.content,
          labelAudio: audioPaths[index] || null
        }
      }));
      
      setZones(updatedZones);
    };
    reader.readAsText(file);
  }
};

const handleLabelAudioFilesUpload = (event) => {
  const files = Array.from(event.target.files);
  const updatedZones = zones.map((zone, index) => ({
    ...zone,
    content: {
      ...zone.content,
      labelAudioFile: files[index] || null
    }
  }));
  setZones(updatedZones);
};

// Fonctions pour Text Audio
const generateTextAudioCSV = (zones) => {
  const header = "Zone Label,Zone Text Preview,Text Audio Path";
  const rows = zones.map(zone => {
    const label = zone.label?.replace(/,/g, '[COMMA]') || '';
    // Prendre les 30 premiers caractères du texte comme aperçu
    const textPreview = (zone.content?.text || '')
      .replace(/,/g, '[COMMA]')
      .replace(/\n/g, ' ')
      .slice(0, 30) + (zone.content?.text?.length > 30 ? '...' : '');
    const audioPath = zone.content?.textAudio || '';
    return `${label},${textPreview},${audioPath}`;
  }).join('\n');
  return `${header}\n${rows}`;
};

const handleDownloadTextAudioCSV = () => {
  const csvContent = generateTextAudioCSV(zones);
  const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'text_audio_paths.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleTextAudioCSVUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      const lines = content.split('\n');
      lines.shift(); // Enlever l'en-tête
      
      // On ne prend que les chemins audio (3ème colonne)
      const audioPaths = lines.map(line => {
        const columns = line.split(',');
        return columns[2]?.trim() || null;
      });
      
      const updatedZones = zones.map((zone, index) => ({
        ...zone,
        content: {
          ...zone.content,
          textAudio: audioPaths[index] || null
        }
      }));
      
      setZones(updatedZones);
    };
    reader.readAsText(file);
  }
};

const handleTextAudioFilesUpload = (event) => {
  const files = Array.from(event.target.files);
  const updatedZones = zones.map((zone, index) => ({
    ...zone,
    content: {
      ...zone.content,
      textAudioFile: files[index] || null
    }
  }));
  setZones(updatedZones);
};


  const handleZoneUpdate = () => {
    if (selectedId === null) return;

    const updatedZones = zones.map((zone, index) => {
      if (index === selectedId) {
        const updatedZone = { ...zone };
        updatedZone.label = editedZone.label;
        updatedZone.content = { ...zone.content };

        // Mise à jour du texte
        if (editedZone.text) {
          updatedZone.content.text = editedZone.text;
        } else {
          delete updatedZone.content.text; // Supprimer si le texte est vide
        }

        // Gestion de l'audio lié au texte
        if (editedZone.textAudioFile) {
          updatedZone.content.textAudioFile = editedZone.textAudioFile;
          // Le backend gérera le remplacement
        } else if (editedZone.existingTextAudio === null) {
          // Audio lié au texte supprimé
          delete updatedZone.content.textAudioFile;
          delete updatedZone.content.textAudio;
        }

        // Gestion de l'audio lié au label
        if (editedZone.labelAudioFile) {
          updatedZone.content.labelAudioFile = editedZone.labelAudioFile;
          // Le backend gérera le remplacement
        } else if (editedZone.existingLabelAudio === null) {
          // Audio lié au label supprimé
          delete updatedZone.content.labelAudioFile;
          delete updatedZone.content.labelAudio;
        }

        // Gestion de l'image
        if (editedZone.imageFile) {
          updatedZone.content.imageFile = editedZone.imageFile;
          // Le backend gérera le remplacement
        } else if (editedZone.existingImage === null) {
          // Image supprimée
          delete updatedZone.content.imageFile;
          delete updatedZone.content.image;
        }

        // Gestion de l'audio
        if (editedZone.audioFile) {
          updatedZone.content.audioFile = editedZone.audioFile;
          // Le backend gérera le remplacement
        } else if (editedZone.existingAudio === null) {
          // Audio supprimé
          delete updatedZone.content.audioFile;
          delete updatedZone.content.audio;
        }

        // Gestion de la vidéo
        if (editedZone.videoFile) {
          updatedZone.content.videoFile = editedZone.videoFile;
          // Le backend gérera le remplacement
        } else if (editedZone.existingVideo === null) {
          // Vidéo supprimée
          delete updatedZone.content.videoFile;
          delete updatedZone.content.video;
        }

        // Gestion de l'application
        if (editedZone.applicationType) {
        const selectedApp = AVAILABLE_APPLICATIONS.find(app => app.id === editedZone.applicationType);
        if (selectedApp) {
        console.log('Application sélectionnée:', selectedApp); // Debug
    
    updatedZone.content.application = {
      type: editedZone.applicationType,
      config: {
        ...selectedApp.defaultConfig,
        // Si c'est une application DJ, on garde les chemins des tracks
        ...(editedZone.applicationType === 'dj' && {
          track1: '/uploads/track1.mp3',  // À remplacer par vos vrais fichiers
          track2: '/uploads/track2.mp3'
        })
      }
    };
    
    // Log pour debug
    console.log('Application sauvegardée:', updatedZone.content.application);
  }
} else {
  delete updatedZone.content.application;
}

        return updatedZone;
      }
      return zone;
    });

    setZones(updatedZones);
  };

  // Gestion du drag and drop pour chaque type de fichier
  const {
    getRootProps: getLabelAudioRootProps,
    getInputProps: getLabelAudioInputProps,
    isDragActive: isLabelAudioDragActive,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      setEditedZone((prev) => ({ ...prev, labelAudioFile: acceptedFiles[0] }));
    },
    accept: {
      'audio/mpeg': [],
    },
  });

  const {
    getRootProps: getTextAudioRootProps,
    getInputProps: getTextAudioInputProps,
    isDragActive: isTextAudioDragActive,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      setEditedZone((prev) => ({ ...prev, textAudioFile: acceptedFiles[0] }));
    },
    accept: {
      'audio/mpeg': [],
    },
  });


  const {
    getRootProps: getVideoRootProps,
    getInputProps: getVideoInputProps,
    isDragActive: isVideoDragActive,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      setEditedZone((prev) => ({ ...prev, videoFile: acceptedFiles[0] }));
    },
    accept: {
      'video/mp4': [],
    },
  });

  const {
    getRootProps: getImageRootProps,
    getInputProps: getImageInputProps,
    isDragActive: isImageDragActive,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      setEditedZone((prev) => ({ ...prev, imageFile: acceptedFiles[0] }));
    },
    accept: {
      'image/*': [],
    },
  });

  const {
    getRootProps: getAudioRootProps,
    getInputProps: getAudioInputProps,
    isDragActive: isAudioDragActive,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      setEditedZone((prev) => ({ ...prev, audioFile: acceptedFiles[0] }));
    },
    accept: {
      'audio/mpeg': [],
    },
  });

  return (
    <div className="illustration-editor">
      {previewIllustration ? (
        <>
          <div className="illustration-container" ref={containerRef}>
            <div className="stage-wrapper">
              <Stage
                width={stageSize.width}
                height={stageSize.height}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                ref={stageRef}
                style={{
                  border: '1px solid #ccc',
                  margin: 'auto',
                  display: 'block',
                }}
              >
                <Layer>
                  {image && (
                    <>
                      <KonvaImage
                        image={image}
                        width={stageSize.width}
                        height={stageSize.height}
                        listening={false} // Désactiver l'écoute des événements sur l'image
                      />
                      {zones.map((zone, index) => (
                        <React.Fragment key={index}>
                          <Rect
                            id={`zone-${index}`}
                            x={zone.x * (stageSize.width / image.width)}
                            y={zone.y * (stageSize.height / image.height)}
                            width={zone.width * (stageSize.width / image.width)}
                            height={zone.height * (stageSize.height / image.height)}
                            fill="rgba(0, 123, 255, 0.1)" // Bleu clair transparent
                            stroke="rgba(0, 123, 255, 0.5)" // Bordure bleu clair
                            strokeWidth={2}
                            draggable
                            onClick={() => handleSelect(index)}
                            onTap={() => handleSelect(index)}
                            onDragEnd={(e) => handleDragEnd(e, index)}
                            onTransformEnd={(e) => handleTransformEnd(e, index)}
                          />
                          {selectedId === index && <Transformer ref={transformerRef} />}
                        </React.Fragment>
                      ))}
                    </>
                  )}
                  {newZone && (
                    <Rect
                      x={newZone.x}
                      y={newZone.y}
                      width={newZone.width}
                      height={newZone.height}
                      fill="rgba(0, 123, 255, 0.1)"
                      stroke="rgba(0, 123, 255, 0.5)"
                      strokeWidth={2}
                    />
                  )}
                </Layer>
              </Stage>
            </div>
          </div>




   {/* Zone editing form */}
{/* Zone editing form */}
{selectedId !== null && (
  <div className="zone-edit-section">
    <h3>Modifier la Zone</h3>
    <div className="zone-edit-form">
      {/* Label */}
      <div className="form-group">
        <label>
          Label<span className="required">*</span>
        </label>
        <input
          type="text"
          name="label"
          value={editedZone.label}
          onChange={handleEditedZoneChange}
          required
        />
      </div>

      {/* Audio lié au label */}
      <div className="form-group">
        <label>Audio lié au label (MP3)</label>
        <div
          {...getLabelAudioRootProps()}
          className={`drop-zone ${isLabelAudioDragActive ? 'active' : ''}`}
        >
          <input {...getLabelAudioInputProps()} />
          {isLabelAudioDragActive ? (
            <p>Déposez l'audio lié au label ici</p>
          ) : (
            <p>Glissez-déposez l'audio lié au label ou cliquez pour le sélectionner</p>
          )}
        </div>
        {(editedZone.existingLabelAudio || editedZone.labelAudioFile) && (
          <div className="preview">
            <audio controls>
              <source
                src={
                  editedZone.labelAudioFile
                    ? URL.createObjectURL(editedZone.labelAudioFile)
                    : editedZone.existingLabelAudio
                    ? `${baseURL}${editedZone.existingLabelAudio}`
                    : ''
                }
                type="audio/mpeg"
              />
              Votre navigateur ne supporte pas l'élément audio.
            </audio>
            <button type="button" onClick={handleDeleteContent('labelAudio')}>
              Supprimer l'audio lié au label
            </button>
          </div>
        )}
      </div>

      {/* Texte */}
      <div className="form-group">
        <label>Texte</label>
        <textarea
          name="text"
          value={editedZone.text}
          onChange={handleEditedZoneChange}
        />
      </div>

      {/* Audio lié au texte */}
      <div className="form-group">
        <label>Audio lié au texte (MP3)</label>
        <div
          {...getTextAudioRootProps()}
          className={`drop-zone ${isTextAudioDragActive ? 'active' : ''}`}
        >
          <input {...getTextAudioInputProps()} />
          {isTextAudioDragActive ? (
            <p>Déposez l'audio lié au texte ici</p>
          ) : (
            <p>Glissez-déposez l'audio lié au texte ou cliquez pour le sélectionner</p>
          )}
        </div>
        {(editedZone.existingTextAudio || editedZone.textAudioFile) && (
          <div className="preview">
            <audio controls>
              <source
                src={
                  editedZone.textAudioFile
                    ? URL.createObjectURL(editedZone.textAudioFile)
                    : editedZone.existingTextAudio
                    ? `${baseURL}${editedZone.existingTextAudio}`
                    : ''
                }
                type="audio/mpeg"
              />
              Votre navigateur ne supporte pas l'élément audio.
            </audio>
            <button type="button" onClick={handleDeleteContent('textAudio')}>
              Supprimer l'audio lié au texte
            </button>
          </div>
        )}
      </div>

      {/* Image */}
      <div className="form-group">
        <label>Image</label>
        <div
          {...getImageRootProps()}
          className={`drop-zone ${isImageDragActive ? 'active' : ''}`}
        >
          <input {...getImageInputProps()} />
          {isImageDragActive ? (
            <p>Déposez l'image ici</p>
          ) : (
            <p>Glissez-déposez une image ou cliquez pour la sélectionner</p>
          )}
        </div>

        {(editedZone.existingImage || editedZone.imageFile) && (
  <div className="preview">
    <img
      src={
        editedZone.imageFile
          ? URL.createObjectURL(editedZone.imageFile)
          : editedZone.existingImage
          ? editedZone.existingImage
          : ''
      }
              alt="Aperçu de la zone"
            />
            <button type="button" onClick={handleDeleteContent('image')}>
              Supprimer l'image
            </button>
          </div>
        )}
      </div>

      {/* Audio */}
      <div className="form-group">
        <label>Audio (MP3)</label>
        <div
          {...getAudioRootProps()}
          className={`drop-zone ${isAudioDragActive ? 'active' : ''}`}
        >
          <input {...getAudioInputProps()} />
          {isAudioDragActive ? (
            <p>Déposez l'audio ici</p>
          ) : (
            <p>Glissez-déposez un audio ou cliquez pour le sélectionner</p>
          )}
        </div>
        {(editedZone.existingAudio || editedZone.audioFile) && (
          <div className="preview">
            <audio controls>
              <source
                src={
                  editedZone.audioFile
                    ? URL.createObjectURL(editedZone.audioFile)
                    : editedZone.existingAudio
                    ? `${baseURL}${editedZone.existingAudio}`
                    : ''
                }
                type="audio/mpeg"
              />
              Votre navigateur ne supporte pas l'élément audio.
            </audio>
            <button type="button" onClick={handleDeleteContent('audio')}>
              Supprimer l'audio
            </button>
          </div>
        )}
      </div>

      {/* Vidéo */}
      <div className="form-group">
        <label>Vidéo (MP4)</label>
        <div
          {...getVideoRootProps()}
          className={`drop-zone ${isVideoDragActive ? 'active' : ''}`}
        >
          <input {...getVideoInputProps()} />
          {isVideoDragActive ? (
            <p>Déposez la vidéo ici</p>
          ) : (
            <p>Glissez-déposez une vidéo ou cliquez pour la sélectionner</p>
          )}
        </div>
        {(editedZone.existingVideo || editedZone.videoFile) && (
          <div className="preview">
            <video controls width="250">
              <source
                src={
                  editedZone.videoFile
                    ? URL.createObjectURL(editedZone.videoFile)
                    : editedZone.existingVideo
                    ? `${baseURL}${editedZone.existingVideo}`
                    : ''
                }
                type="video/mp4"
              />
              Votre navigateur ne supporte pas l'élément vidéo.
            </video>
            <button type="button" onClick={handleDeleteContent('video')}>
              Supprimer la vidéo
            </button>
          </div>
        )}
      </div>

{/* Sélection d'application */}
<div className="form-group">
  <label>Application</label>
  <select
    name="applicationType"
    value={editedZone.applicationType || ''}
    onChange={(e) => {
      const selectedType = e.target.value;
      if (selectedType === '') {
        // Si "Aucune" est sélectionné, supprimer l'application
        setEditedZone(prev => ({
          ...prev,
          applicationType: '',
          application: null
        }));
      } else {
        // Si une application est sélectionnée, initialiser avec sa configuration par défaut
        const selectedApp = AVAILABLE_APPLICATIONS.find(app => app.id === selectedType);
        setEditedZone(prev => ({
          ...prev,
          applicationType: selectedType,
          application: {
            type: selectedType,
            config: { ...selectedApp.defaultConfig }
          }
        }));
      }
    }}
  >
    <option value="">Aucune application</option>
    {AVAILABLE_APPLICATIONS.map(app => (
      <option key={app.id} value={app.id}>
        {app.name}
      </option>
    ))}
  </select>

  {/* Afficher la configuration spécifique à l'application si nécessaire */}
  {editedZone.applicationType && (
    <div className="application-config">
      {/* Ici, vous pouvez ajouter des champs de configuration spécifiques 
          selon le type d'application sélectionné */}
      <p>Application sélectionnée : {
        AVAILABLE_APPLICATIONS.find(app => app.id === editedZone.applicationType)?.name
      }</p>
    </div>
)}
</div>



{/* Aperçu de l'application si sélectionnée */}
{editedZone.applicationType && (
  <div className="application-preview">
    <p>Application sélectionnée : {editedZone.applicationType}</p>
    <button 
      type="button" 
      onClick={() => handleDeleteContent('application')}
    >
      Supprimer l'application
    </button>
  </div>
)}


      {/* Boutons d'action */}
      <button
        type="button"
        className="submit-button"
        onClick={handleZoneUpdate}
      >
        Enregistrer les Modifications
      </button>
      <button
        type="button"
        className="cancel-button"
        onClick={() => setSelectedId(null)}
      >
        Annuler
      </button>
      <button
        type="button"
        className="delete-button"
        onClick={handleDeleteZone}
      >
        Supprimer la Zone
      </button>
    </div>
  </div>
)}


{/* Sections de modification en masse */}
<div className="bulk-edit-sections">
  {/* Section 1: Labels et Textes */}
  <div className="bulk-edit-section">
    <h3>Modifier toutes les zones du block</h3>
    <div className="csv-controls">
      <div className="download-csv">
        <button 
          type="button"
          className="csv-button"
          onClick={handleDownloadCSV}
        >
          Télécharger le fichier CSV des labels et textes
        </button>
        <p className="csv-info">
          Le fichier CSV contient les labels et textes de toutes les zones
        </p>
      </div>

      <div className="upload-csv">
        <label htmlFor="csv-upload" className="csv-upload-label">
          Importer un fichier CSV modifié
        </label>
        <input
          type="file"
          id="csv-upload"
          accept=".csv"
          onChange={handleCSVUpload}
          className="csv-upload-input"
        />
        <p className="csv-info">
          Les modifications seront appliquées à tous les labels et textes
        </p>
      </div>
    </div>
  </div>

  {/* Section 2: Label Audio */}
  <div className="bulk-edit-section">
    <h3>Modifier tous les Label Audio des zones du block</h3>
    <div className="csv-controls">
      <div className="download-csv">
        <button 
          type="button"
          className="csv-button"
          onClick={handleDownloadLabelAudioCSV}
        >
          Télécharger le fichier CSV des Label Audio
        </button>
        <p className="csv-info">
          Le fichier CSV contient les chemins des Label Audio de toutes les zones
        </p>
      </div>

      <div className="upload-csv">
        <label htmlFor="label-audio-csv-upload" className="csv-upload-label">
          Importer un fichier CSV des Label Audio modifié
        </label>
        <input
          type="file"
          id="label-audio-csv-upload"
          accept=".csv"
          onChange={handleLabelAudioCSVUpload}
          className="csv-upload-input"
        />
        <p className="csv-info">
          Les modifications seront appliquées à tous les Label Audio
        </p>
      </div>

      <div className="upload-files">
        <label htmlFor="label-audio-files" className="file-upload-label">
          Importer les fichiers audio des labels
        </label>
        <input
          type="file"
          id="label-audio-files"
          accept="audio/mpeg"
          multiple
          onChange={handleLabelAudioFilesUpload}
          className="file-upload-input"
        />
        <p className="csv-info">
          Sélectionnez tous les fichiers MP3 dans l'ordre correspondant aux zones
        </p>
      </div>
    </div>
  </div>

  {/* Section 3: Text Audio */}
  <div className="bulk-edit-section">
    <h3>Modifier tous les Text Audio des zones du block</h3>
    <div className="csv-controls">
      <div className="download-csv">
        <button 
          type="button"
          className="csv-button"
          onClick={handleDownloadTextAudioCSV}
        >
          Télécharger le fichier CSV des Text Audio
        </button>
        <p className="csv-info">
          Le fichier CSV contient les chemins des Text Audio de toutes les zones
        </p>
      </div>

      <div className="upload-csv">
        <label htmlFor="text-audio-csv-upload" className="csv-upload-label">
          Importer un fichier CSV des Text Audio modifié
        </label>
        <input
          type="file"
          id="text-audio-csv-upload"
          accept=".csv"
          onChange={handleTextAudioCSVUpload}
          className="csv-upload-input"
        />
        <p className="csv-info">
          Les modifications seront appliquées à tous les Text Audio
        </p>
      </div>

      <div className="upload-files">
        <label htmlFor="text-audio-files" className="file-upload-label">
          Importer les fichiers audio des textes
        </label>
        <input
          type="file"
          id="text-audio-files"
          accept="audio/mpeg"
          multiple
          onChange={handleTextAudioFilesUpload}
          className="file-upload-input"
        />
        <p className="csv-info">
          Sélectionnez tous les fichiers MP3 dans l'ordre correspondant aux zones
        </p>
      </div>
    </div>
  </div>
</div>

        </>
      ) : (
        <p>Aucune illustration disponible</p>
      )}
    </div>
  );
}

export default IllustrationEditor;