import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import './Header.css';
import API_BASE_URL from '../config';

import editIcon from '../assets/icons/Edit.png';
import deleteIcon from '../assets/icons/Delete.png';

function Header() {
  const { t } = useTranslation();  // For translations
  const location = useLocation();  // To get the current path
  const navigate = useNavigate();  // For navigation after delete
  const { isCreator, logout } = useAuth(); // Get auth context

  // Extract the current path to check if it matches /showcase/:id
  const pathname = location.pathname;
  const showcaseMatch = pathname.match(/^\/showcase\/([a-fA-F0-9]{24})$/);
  const blockId = showcaseMatch ? showcaseMatch[1] : null;

  // Function to handle block deletion
  const handleDelete = async () => {
    const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer ce bloc ?');
    if (!confirmDelete) return;

    try {
      // Utiliser le token stocké dans localStorage
      const token = localStorage.getItem('token');
      await axios.delete(`${API_BASE_URL}/api/blocks/${blockId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      alert('Bloc supprimé avec succès.');
      navigate('/');
    } catch (err) {
      console.error('Erreur lors de la suppression du bloc :', err);
      if (err.response?.status === 401 || err.response?.status === 403) {
        alert('Accès non autorisé. Veuillez vous connecter en tant que créateur.');
        navigate('/connect');
      } else {
        alert('Échec de la suppression du bloc. Veuillez réessayer.');
      }
    }
  };

  // Handle logout
  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <header className="header">
      <div className="header-left">
        <div className="logo-title-container">
          <Link to="/" className="logo-link">
            <img src="/6ko.png" alt="Bitgrid Logo" className="header-logo" />
          </Link>
          <Link to="/" className="header-title">Bitgrid</Link>
        </div>
        <nav>
          <ul className="header-nav">
            {/* Add new block link - only visible for creators */}
            {isCreator && (
              <li className={pathname === '/add' ? 'active' : ''}>
                <Link to="/add" className="nav-link">{t('Add')}</Link>
              </li>
            )}
          </ul>
        </nav>
      </div>

      <div className="header-right">
        {/* Edit/Delete buttons - only visible for creators and when viewing a block */}
        {isCreator && blockId && (
          <>
            <Link to={`/edit/${blockId}`} className="header-action-button" title="Éditer">
              <img src={editIcon} alt="Éditer" className="header-action-icon" />
            </Link>
            <button onClick={handleDelete} className="header-action-button" title="Supprimer">
              <img src={deleteIcon} alt="Supprimer" className="header-action-icon" />
            </button>
          </>
        )}

        {/* Login/Logout button */}
        <div className="auth-buttons">
          {isCreator && (
            <button onClick={handleLogout} className="auth-button">
              {t('Logout')}
            </button>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;