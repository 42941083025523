import React, { useState, useEffect } from 'react';
import TurntableVinyl from './TurntableVinyl';
import VolumeIndicator from './VolumeIndicator';
import { detectBPM } from './BPMDetector';
import './DJMiniApp.css';

const DJMiniApp = ({ track1Url, track2Url }) => {
  // États pour les pistes audio
  const [track1, setTrack1] = useState(null);
  const [track2, setTrack2] = useState(null);
  const [bpm1, setBpm1] = useState(120);
  const [bpm2, setBpm2] = useState(120);
  const [volume1, setVolume1] = useState(0);
  const [volume2, setVolume2] = useState(0);
  const [crossfade, setCrossfade] = useState(50);
  const [isLoading, setIsLoading] = useState(true);

  // Chargement initial des pistes et détection BPM
  useEffect(() => {
    const initializeTracks = async () => {
      setIsLoading(true);
      try {
        // Charger les pistes et détecter le BPM
        const [audioContext1, audioContext2] = await Promise.all([
          new AudioContext(),
          new AudioContext()
        ]);

        const [response1, response2] = await Promise.all([
          fetch(track1Url),
          fetch(track2Url)
        ]);

        const [buffer1, buffer2] = await Promise.all([
          response1.arrayBuffer(),
          response2.arrayBuffer()
        ]);

        const [audioBuffer1, audioBuffer2] = await Promise.all([
          audioContext1.decodeAudioData(buffer1),
          audioContext2.decodeAudioData(buffer2)
        ]);

        setTrack1({ context: audioContext1, buffer: audioBuffer1 });
        setTrack2({ context: audioContext2, buffer: audioBuffer2 });

        // Détecter les BPM
        const bpm1 = await detectBPM(audioBuffer1);
        const bpm2 = await detectBPM(audioBuffer2);
        
        setBpm1(bpm1);
        setBpm2(bpm2);
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading tracks:', error);
        setIsLoading(false);
      }
    };

    initializeTracks();

    return () => {
      // Cleanup audio contexts
      if (track1?.context) track1.context.close();
      if (track2?.context) track2.context.close();
    };
  }, [track1Url, track2Url]);

  // Gestionnaire pour le changement de crossfade
  const handleCrossfadeChange = (value) => {
    setCrossfade(value);
    // Calculer les volumes selon une courbe logarithmique pour un fade plus naturel
    const vol1 = Math.cos(value * 0.5 * Math.PI / 100);
    const vol2 = Math.cos((100 - value) * 0.5 * Math.PI / 100);
    
    setVolume1(vol1);
    setVolume2(vol2);

    if (track1?.gainNode) track1.gainNode.gain.value = vol1;
    if (track2?.gainNode) track2.gainNode.gain.value = vol2;
  };

  // Gestionnaire pour le changement de BPM
  const handleBPMChange = (trackNumber, newBPM) => {
    if (trackNumber === 1) {
      setBpm1(newBPM);
      if (track1?.source) {
        track1.source.playbackRate.value = newBPM / track1.originalBPM;
      }
    } else {
      setBpm2(newBPM);
      if (track2?.source) {
        track2.source.playbackRate.value = newBPM / track2.originalBPM;
      }
    }
  };

  if (isLoading) {
    return <div className="dj-loading">Loading tracks...</div>;
  }

  return (
    <div className="dj-container">
      <div className="turntables-container">
        {/* Rangée des platines */}
        <div className="turntables-row">
          {/* Platine gauche */}
          <div className="turntable-section">
            <div className="bpm-display">BPM: {Math.round(bpm1)}</div>
            <div className="controls-left">
              <input
                type="range"
                min="60"
                max="180"
                value={bpm1}
                onChange={(e) => handleBPMChange(1, Number(e.target.value))}
                className="bpm-slider-vertical"
              />
            </div>
            <TurntableVinyl
              isPlaying={track1?.isPlaying}
              bpm={bpm1}
              onTogglePlay={() => {/* Toggle play logic */}}
            />
          </div>
  
          {/* Platine droite */}
          <div className="turntable-section">
            <div className="bpm-display">BPM: {Math.round(bpm2)}</div>
            <TurntableVinyl
              isPlaying={track2?.isPlaying}
              bpm={bpm2}
              onTogglePlay={() => {/* Toggle play logic */}}
            />
            <div className="controls-right">
              <input
                type="range"
                min="60"
                max="180"
                value={bpm2}
                onChange={(e) => handleBPMChange(2, Number(e.target.value))}
                className="bpm-slider-vertical"
              />
            </div>
          </div>
        </div>
  
        {/* Rangée des indicateurs de volume */}
        <div className="volume-indicators-row">
          <VolumeIndicator volume={volume1} />
          <VolumeIndicator volume={volume2} />
        </div>
  
        {/* Section du crossfader */}
        <div className="crossfader-section">
          <input
            type="range"
            min="0"
            max="100"
            value={crossfade}
            onChange={(e) => handleCrossfadeChange(Number(e.target.value))}
            className="crossfader"
          />
        </div>
      </div>
    </div>
  );
};

export default DJMiniApp;