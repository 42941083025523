import React, { useEffect, useRef } from 'react';

const VolumeIndicator = ({ volume }) => {
  const canvasRef = useRef(null);
  const ledCount = 10; // Réduit de 20 à 10

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    
    ctx.clearRect(0, 0, width, height);

    const ledHeight = (height - (ledCount + 1)) / ledCount;
    const ledWidth = width - 2; // Réduit de 4 à 2

    for (let i = 0; i < ledCount; i++) {
      const y = height - (i + 1) * (ledHeight + 1);
      const ledVolume = i / ledCount;
      
      let color;
      if (ledVolume > 0.8) {
        color = volume > ledVolume ? '#ff0000' : '#330000';
      } else if (ledVolume > 0.6) {
        color = volume > ledVolume ? '#ffff00' : '#333300';
      } else {
        color = volume > ledVolume ? '#00ff00' : '#003300';
      }

      ctx.fillStyle = color;
      ctx.fillRect(1, y, ledWidth, ledHeight);

      if (volume > ledVolume) {
        ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
        ctx.fillRect(1, y, ledWidth / 2, ledHeight);
      }
    }
  }, [volume]);

  return (
    <div className="volume-indicator">
      <canvas
        ref={canvasRef}
        width="10"
        height="100"
        style={{
          background: '#111',
          borderRadius: '2px',
          boxShadow: 'inset 0 0 3px rgba(0,0,0,0.5)'
        }}
      />
      
      <style jsx>{`
        .volume-indicator {
          padding: 2px;
          background: #222;
          border-radius: 3px;
          margin: 5px;
        }
      `}</style>
    </div>
  );
};

export default VolumeIndicator;