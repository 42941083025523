import React, { useEffect, useRef } from 'react';

const TurntableVinyl = ({ isPlaying, bpm, onTogglePlay }) => {
  const rotationRef = useRef(0);
  const requestRef = useRef();
  const previousTimeRef = useRef();

  useEffect(() => {
    if (!isPlaying) {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
      return;
    }

    const animate = (time) => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;
        const rotationSpeed = (bpm * 360) / (60 * 1000);
        rotationRef.current += deltaTime * rotationSpeed;
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [isPlaying, bpm]);

  return (
    <div className="turntable-vinyl" onClick={onTogglePlay}>
      <div 
        className="vinyl-disc"
        style={{
          transform: `rotate(${rotationRef.current}deg)`
        }}
      >
        <div className="vinyl-label">
          <div className="center-hole" />
        </div>
      </div>
      
      <style jsx>{`
        .turntable-vinyl {
          width: 150px;
          height: 150px;
          position: relative;
          cursor: pointer;
          background: #222;
          border-radius: 50%;
          padding: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        }

        .vinyl-disc {
          width: 100%;
          height: 100%;
          background: #111;
          border-radius: 50%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        }

        .vinyl-disc::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: repeating-radial-gradient(
            circle at center,
            #222 0px,
            #222 0.5px,
            #111 0.5px,
            #111 1px
          );
          border-radius: 50%;
          opacity: 0.7;
        }

        .vinyl-label {
          width: 50px;
          height: 50px;
          background: #444;
          border-radius: 50%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(45deg, #333, #555);
        }

        .center-hole {
          width: 6px;
          height: 6px;
          background: #000;
          border-radius: 50%;
          border: 1px solid #666;
        }

        @media (max-width: 480px) {
          .turntable-vinyl {
            width: 100px;
            height: 100px;
          }

          .vinyl-label {
            width: 35px;
            height: 35px;
          }
        }
      `}</style>
    </div>
  );
};

export default TurntableVinyl;