// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
// import other languages here

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      // other languages
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });

export default i18n;
