// src/pages/CategoryIconsPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './CategoryIconsPage.css';  // Vous pouvez ajouter des styles ici
import categoryIcons from '../assets/categories';  // Importez l'objet des icônes

function CategoryIconsPage() {
  const [categories, setCategories] = useState([]);

  // Fetch blocks and extract unique categories
  useEffect(() => {
    axios.get('${API_BASE_URL}/api/blocks')
      .then(res => {
        const blocks = res.data;
        // Extract unique categories from the blocks
        const uniqueCategories = [...new Set(blocks.flatMap(block => block.categories))];
        setCategories(uniqueCategories);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <div className="category-icons-page">
      <h2>Catégories Disponibles</h2>
      <div className="category-icons-container">
        {categories.map(category => (
          <Link 
            to={`/category/${encodeURIComponent(category)}`} 
            key={category} 
            className="category-link"
          >
            <div className="category-icon-card">
              <img
                src={categoryIcons[category] || '/assets/default.png'}  // Utilisez l'icône correspondante ou une icône par défaut
                alt={category}
                className="category-icon"
              />
              <p>{category}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default CategoryIconsPage;
