// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { MediaProvider } from './contexts/MediaContext';
import { AuthProvider } from './contexts/AuthContext';
import Header from './components/Header';
import ListBlocks from './pages/ListBlocks';
import ShowcaseBlock from './pages/ShowcaseBlock';
import EditBlock from './pages/EditBlock';
import CategoryIconsPage from './pages/CategoryIconsPage';
import BlockIconsPage from './pages/BlockIconsPage';
import LoginPage from './pages/LoginPage';
import { useEffect } from 'react';
import { useAuth } from './contexts/AuthContext';

// Composant ScrollToTop
const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};

// Page 404
const NotFound = () => (
  <div>
    <h2>Page Not Found</h2>
    <p>Sorry, the page you are looking for does not exist.</p>
  </div>
);

// Composant pour les routes protégées
const ProtectedRoute = ({ children }) => {
  const { isCreator } = useAuth();
  
  if (!isCreator) {
    return <Navigate to="/connect" replace />;
  }
  
  return children;
};

// Composant principal App
function AppContent() {
  return (
    <MediaProvider>
      <Router>
        <Header />
        <main className="content">
          <ScrollToTop />
          <Routes>
            {/* Routes publiques */}
            <Route path="/" element={<ListBlocks />} />
            <Route path="/showcase/:id" element={<ShowcaseBlock />} />
            <Route path="/categories" element={<CategoryIconsPage />} />
            <Route path="/block-icons" element={<BlockIconsPage />} />
            <Route path="/category/:category" element={<BlockIconsPage />} />
            <Route path="/connect" element={<LoginPage />} />

            {/* Routes protégées (créateur uniquement) */}
            <Route 
              path="/add" 
              element={
                <ProtectedRoute>
                  <EditBlock />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/edit/:id" 
              element={
                <ProtectedRoute>
                  <EditBlock />
                </ProtectedRoute>
              } 
            />

            {/* Page 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </Router>
    </MediaProvider>
  );
}

// Wrapper avec AuthProvider
function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;