// src/components/Illustration.js

import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Image as KonvaImage, Rect } from 'react-konva';
import useImage from 'use-image';
import './Illustration.css';

function Illustration({ illustrationUrl, areas, handleZoneClick }) {
  const [image] = useImage(illustrationUrl);
  const containerRef = useRef(null);
  const [stageSize, setStageSize] = useState({ width: 800, height: 600 });
  const [scale, setScale] = useState(1);

  // Fonction pour mettre à jour la taille du Stage en fonction du conteneur et de l'image
  const updateStageSize = () => {
    if (containerRef.current && image && image.width && image.height) {
      const containerWidth = containerRef.current.clientWidth;
      const imageAspectRatio = image.width / image.height;

      // Calculer la hauteur basée sur la largeur et l'aspect ratio
      const newWidth = containerWidth;
      const newHeight = newWidth / imageAspectRatio;

      setStageSize({ width: newWidth, height: newHeight });
      setScale(newWidth / image.width);
    }
  };

  // Mettre à jour la taille du Stage lorsque l'image est chargée ou lorsque la fenêtre est redimensionnée
  useEffect(() => {
    if (image) {
      updateStageSize();
    }

    window.addEventListener('resize', updateStageSize);
    return () => window.removeEventListener('resize', updateStageSize);
  }, [image]);

  // Mettre à jour la taille du Stage lorsque l'URL de l'illustration change
  useEffect(() => {
    updateStageSize();
  }, [illustrationUrl, image]);

  return (
    <div className="illustration-container" ref={containerRef}>
      {illustrationUrl && image ? (
        <Stage
          width={stageSize.width}
          height={stageSize.height}
          style={{ display: 'block' }} // Remplir entièrement le conteneur sans forcer la hauteur
        >
          <Layer scaleX={scale} scaleY={scale}>
            {/* Afficher l'image sans déformation */}
            <KonvaImage
              image={image}
              x={0}
              y={0}
              width={image.width}
              height={image.height}
              listening={false} // Désactiver les interactions directes sur l'image
            />
            {/* Rendre les zones interactives */}
            {areas.map((area, index) => (
              <Rect
                key={index}
                x={area.x}
                y={area.y}
                width={area.width}
                height={area.height}
                fill="rgba(128, 128, 128, 0.1)"
                strokeWidth={0}
                onClick={() => handleZoneClick(area)}
                onTap={() => handleZoneClick(area)}
                onMouseEnter={(e) => {
                  const container = e.target.getStage().container();
                  container.style.cursor = 'pointer';
                }}
                onMouseLeave={(e) => {
                  const container = e.target.getStage().container();
                  container.style.cursor = 'default';
                }}
              />
            ))}
          </Layer>
        </Stage>
      ) : (
        <p>Aucune illustration disponible</p>
      )}
    </div>
  );
}

export default Illustration;
