// src/pages/BlockIconsPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import './BlockIconsPage.css'; // Créez ce fichier CSS

import API_BASE_URL from '../config';

// Importation des drapeaux des langues
import enFlag from '../assets/flags/EN.png';
import spFlag from '../assets/flags/SP.png';
import frFlag from '../assets/flags/FR.png';
import deFlag from '../assets/flags/DE.png';

function BlockIconsPage() {
  const [searchParams] = useSearchParams();
  const { category: routeCategory } = useParams(); // Lecture des paramètres de route
  const navigate = useNavigate();

  const [blocks, setBlocks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [languages] = useState(['EN', 'SP', 'FR', 'DE']); // Liste des langues disponibles
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');

  // Drapeaux des langues
  const languageFlags = {
    EN: enFlag,
    SP: spFlag,
    FR: frFlag,
    DE: deFlag,
  };

  // Récupérer les catégories uniques depuis le backend
  useEffect(() => {
    axios.get('${API_BASE_URL}/api/blocks/categories')
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.error('Erreur lors de la récupération des catégories :', err);
      });
  }, []);

  // Mettre à jour le selectedCategory basé sur les paramètres de route ou de requête
  useEffect(() => {
    const categoryParam = routeCategory || searchParams.get('category') || '';
    setSelectedCategory(categoryParam);
  }, [routeCategory, searchParams.toString()]);

  // Récupérer les blocs en fonction des filtres sélectionnés
  useEffect(() => {
    const params = {};
    if (selectedCategory) {
      params.category = selectedCategory;
    }
    if (selectedLanguage) {
      params.language = selectedLanguage;
    }

    axios.get('${API_BASE_URL}/api/blocks', { params })
      .then((res) => {
        setBlocks(res.data);
      })
      .catch((err) => {
        console.error('Erreur lors de la récupération des blocs :', err);
      });
  }, [selectedCategory, selectedLanguage]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleBlockClick = (blockId) => {
    navigate(`/showcase/${blockId}`);
  };

  return (
    <div className="block-icons-page">
      <h2>Icônes des Blocs Disponibles</h2>
      
      <div className="filters-container">
        {/* Filtre de Langue */}
        <div className="filter">
          <label htmlFor="language-select">Filtrer par Langue:</label>
          <select id="language-select" value={selectedLanguage} onChange={handleLanguageChange}>
            <option value="">Toutes les Langues</option>
            {languages.map((lang) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            ))}
          </select>
          {selectedLanguage && (
            <img src={languageFlags[selectedLanguage]} alt={`${selectedLanguage} Flag`} className="flag-icon" />
          )}
        </div>

        {/* Filtre de Catégorie */}
        <div className="filter">
          <label htmlFor="category-select">Filtrer par Catégorie:</label>
          <select id="category-select" value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">Toutes les Catégories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Affichage des Icônes des Blocs */}
      <div className="blocks-container">
        {blocks.length > 0 ? (
          blocks.map((block) => (
            <div
              key={block._id}
              className="block-icon-card"
              onClick={() => handleBlockClick(block._id)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleBlockClick(block._id);
              }}
            >
              {block.icon ? (
                <img
                  src={`${API_BASE_URL}${block.icon}`}
                  alt={block.title}
                  className="block-icon"
                />
              ) : block.illustration ? (
                <img
                  src={`${API_BASE_URL}${block.illustration}`}
                  alt={block.title}
                  className="block-icon"
                />
              ) : (
                <div className="no-icon">Aucune Icône</div>
              )}
              <p>{block.title}</p>
            </div>
          ))
        ) : (
          <p>Aucun bloc trouvé avec les filtres sélectionnés.</p>
        )}
      </div>
    </div>
  );
}

export default BlockIconsPage;
