// src/contexts/MediaContext.js

import React, { createContext, useState, useRef } from 'react';

// Créer le contexte
export const MediaContext = createContext();

// Fournisseur de contexte
export const MediaProvider = ({ children }) => {
  const songRef = useRef(null);
  const [currentMedia, setCurrentMedia] = useState(null); // 'song', 'zoneContentAudio', 'zoneContentVideo'

  const playSong = () => {
    if (songRef.current) {
      songRef.current.play();
      setCurrentMedia('song');
    }
  };

  const pauseSong = () => {
    if (songRef.current) {
      songRef.current.pause();
      setCurrentMedia(null);
    }
  };

  const playOtherMedia = (mediaType, mediaRef) => {
    if (currentMedia === 'song') {
      pauseSong();
    }
    if (mediaRef.current) {
      mediaRef.current.play();
      setCurrentMedia(mediaType);
    }
  };

  const pauseOtherMedia = (mediaRef) => {
    if (mediaRef.current) {
      mediaRef.current.pause();
      setCurrentMedia(null);
      playSong();
    }
  };

  return (
    <MediaContext.Provider value={{
      songRef,
      playSong,
      pauseSong,
      playOtherMedia,
      pauseOtherMedia,
      currentMedia,
      setCurrentMedia,
    }}>
      {children}
    </MediaContext.Provider>
  );
};
