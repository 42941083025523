import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import IllustrationEditor from '../components/IllustrationEditor';
import './EditBlock.css';
import API_BASE_URL from '../config';

function EditBlock() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isPublic, setIsPublic] = useState(false);

  // État principal du bloc
  const [block, setBlock] = useState({
    title: '',
    titleAudio: '',
    blockNumber: '',
    category1: '',
    category2: '',
    category3: '',
    language: 'EN',
    description: '',
    illustration: '',
    portrait: '',
    pdf: '',
    descriptionlue: '',
    song: '',
    areas: [],
    icon: '',
    backgroundColor: '#FFFFFF', // Ajout de l'état pour la couleur de fond
    secondaryColor: '#F0F0F0', // Ajout de la couleur secondaire
});

  // États pour les fichiers principaux
  const [illustrationFile, setIllustrationFile] = useState(null);
  const [portraitFile, setPortraitFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [descriptionlueFile, setDescriptionlueFile] = useState(null);
  const [songFile, setSongFile] = useState(null);
  const [iconFile, setIconFile] = useState(null);
  const [titleAudioFile, setTitleAudioFile] = useState(null);

  // États pour les prévisualisations
  const [previewIllustration, setPreviewIllustration] = useState(null);
  const [previewPortrait, setPreviewPortrait] = useState(null);
  const [previewPdf, setPreviewPdf] = useState(null);
  const [previewDescriptionlue, setPreviewDescriptionlue] = useState(null);
  const [previewSong, setPreviewSong] = useState(null);
  const [previewIcon, setPreviewIcon] = useState(null);
  const [previewTitleAudio, setPreviewTitleAudio] = useState(null);
  const [songFileType, setSongFileType] = useState('');

  // État pour les zones de l'illustration
  const [zones, setZones] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`${API_BASE_URL}/api/blocks/${id}`)
        .then((res) => {
          const data = res.data;
          setBlock({
            title: data.title || '',
            titleAudio: data.titleAudio || '',
            blockNumber: data.blockNumber || '',
            category1: data.categories[0] || '',
            category2: data.categories[1] || '',
            category3: data.categories[2] || '',
            language: data.language || 'EN',
            description: data.description || '',
            illustration: data.illustration || '',
            portrait: data.portrait || '',
            pdf: data.pdf || '',
            descriptionlue: data.descriptionlue || '',
            song: data.song || '',
            backgroundColor: data.backgroundColor || '#FFFFFF', // Ajout de cette ligne
            secondaryColor: data.secondaryColor || '#F0F0F0', // Ajout ici
            areas: data.areas || [],
            icon: data.icon || '',
          });
          setIsPublic(data.isPublic);

          if (data.illustration) {
            setPreviewIllustration(`${API_BASE_URL}${data.illustration}`);
          }
          if (data.portrait) {
            setPreviewPortrait(`${API_BASE_URL}${data.portrait}`);
          }
          if (data.pdf) {
            setPreviewPdf(`${API_BASE_URL}${data.pdf}`);
          }
          if (data.descriptionlue) {
            setPreviewDescriptionlue(`${API_BASE_URL}${data.descriptionlue}`);
          }
          if (data.song) {
            const fileExtension = data.song.split('.').pop().toLowerCase();
            const fileType = fileExtension === 'mp3' ? 'audio/mpeg' : 'video/mp4';
            setSongFileType(fileType);
            setPreviewSong(`${API_BASE_URL}${data.song}`);
          }
          if (data.icon) {
            setPreviewIcon(`${API_BASE_URL}${data.icon}`);
          }
          if (data.titleAudio) {
            setPreviewTitleAudio(`${API_BASE_URL}${data.titleAudio}`);
          }

          if (data.areas) {
            const updatedZones = data.areas.map((zone) => ({
              ...zone,
              content: {
                ...zone.content,
                application: zone.content.application ? {
                  type: zone.content.application.type,
                  config: zone.content.application.config || {}
                } : undefined        
              },
            }));
            setZones(updatedZones);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [id]);

  // Tous les gestionnaires de changement
  const handleChange = (e) => {
    setBlock({ ...block, [e.target.name]: e.target.value });
  };

  const handlePublicToggle = () => {
    setIsPublic(!isPublic);
  };

  const handleCategoryChange = (e, categoryNumber) => {
    const selectedCategory = e.target.value;
    const newBlock = { ...block };

    if (categoryNumber === 1) {
      newBlock.category1 = selectedCategory;
      if (newBlock.category2 && selectedCategory !== newBlock.category2) {
        newBlock.category2 = '';
      }
      if (newBlock.category3 && selectedCategory !== newBlock.category3) {
        newBlock.category3 = '';
      }
    } else if (categoryNumber === 2) {
      if (selectedCategory === '') {
        newBlock.category2 = '';
        newBlock.category3 = '';
      } else {
        if (newBlock.category3 === selectedCategory) {
          newBlock.category3 = newBlock.category2;
        }
        newBlock.category2 = selectedCategory;
      }
    } else if (categoryNumber === 3) {
      if (selectedCategory === '') {
        newBlock.category3 = '';
      } else {
        newBlock.category3 = selectedCategory;
      }
    }

    setBlock(newBlock);
  };

  // Gestionnaires des fichiers
  const handleIllustrationChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIllustrationFile(file);
      setPreviewIllustration(URL.createObjectURL(file));
    }
  };

  const handlePortraitChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPortraitFile(file);
      setPreviewPortrait(URL.createObjectURL(file));
    }
  };

  const handlePdfChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPdfFile(file);
      setPreviewPdf(URL.createObjectURL(file));
    }
  };

  const handleDescriptionlueChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDescriptionlueFile(file);
      setPreviewDescriptionlue(URL.createObjectURL(file));
    }
  };

  const handleSongChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSongFile(file);
      setPreviewSong(URL.createObjectURL(file));
      setSongFileType(file.type);
    }
  };

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIconFile(file);
      setPreviewIcon(URL.createObjectURL(file));
    }
  };

  const handleTitleAudioChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTitleAudioFile(file);
      setPreviewTitleAudio(URL.createObjectURL(file));
    }
  };

  const handleDuplicate = async () => {
    if (!id) {
      alert('Aucun bloc à dupliquer.');
      return;
    }
  
    const newBlockNumber = window.prompt('Veuillez entrer un nouveau BlockNumber (5 chiffres) :', '');
    if (!newBlockNumber) return;
  
    if (!/^\d{5}$/.test(newBlockNumber)) {
      alert('BlockNumber doit être un numéro à 5 chiffres.');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_BASE_URL}/api/blocks/${id}/duplicate`,
        { newBlockNumber },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
  
      alert('Bloc dupliqué avec succès.');
      navigate(`/edit-block/${response.data._id}`);
    } catch (err) {
      console.error('Erreur lors de la duplication du bloc :', err.response?.data || err.message);
      if (err.response?.status === 401) {
        alert('Session expirée. Veuillez vous reconnecter.');
        navigate('/connect');
      } else {
        alert(err.response?.data?.message || 'Échec de la duplication du bloc.');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation des champs requis
    if (!block.title || !block.blockNumber || !block.category1 || !block.language) {
      alert('Tous les champs requis doivent être remplis.');
      return;
    }

    // Validation du BlockNumber
    const blockNumberRegex = /^\d{5}$/;
    if (!blockNumberRegex.test(block.blockNumber)) {
      alert('BlockNumber doit être un numéro à 5 chiffres.');
      return;
    }

    // Préparation des données
    const categoriesToSend = [block.category1];
    if (block.category2) categoriesToSend.push(block.category2);
    if (block.category3) categoriesToSend.push(block.category3);

    const areasToSend = zones.map((zone) => ({
      x: zone.x,
      y: zone.y,
      width: zone.width,
      height: zone.height,
      label: zone.label,
      content: {
        ...(zone.content.text && { text: zone.content.text }),
        ...(zone.content.image && { image: zone.content.image }),
        ...(zone.content.audio && { audio: zone.content.audio }),
        ...(zone.content.video && { video: zone.content.video }),
        ...(zone.content.textAudio && { textAudio: zone.content.textAudio }),
        ...(zone.content.labelAudio && { labelAudio: zone.content.labelAudio }),
        ...(zone.content.application && {
          application: {
            type: zone.content.application.type,
            config: zone.content.application.config || {}
          }
        }),
      },
    }));

    const formData = new FormData();
    formData.append('isPublic', isPublic);
    formData.append('title', block.title);
    formData.append('blockNumber', block.blockNumber);
    formData.append('categories', JSON.stringify(categoriesToSend));
    formData.append('language', block.language);
    formData.append('description', block.description);
    formData.append('backgroundColor', block.backgroundColor); // Ajout de cette ligne
    formData.append('secondaryColor', block.secondaryColor);
    formData.append('areas', JSON.stringify(areasToSend));

    // Ajout des fichiers
    if (illustrationFile) formData.append('illustration', illustrationFile);
    if (portraitFile) formData.append('portrait', portraitFile);
    if (pdfFile) formData.append('pdf', pdfFile);
    if (descriptionlueFile) formData.append('descriptionlue', descriptionlueFile);
    if (songFile) formData.append('song', songFile);
    if (iconFile) formData.append('icon', iconFile);
    if (titleAudioFile) formData.append('titleAudio', titleAudioFile);

    // Ajout des fichiers des zones
    zones.forEach((zone, index) => {
      if (zone.content?.imageFile) formData.append(`area-${index}-image`, zone.content.imageFile);
      if (zone.content?.audioFile) formData.append(`area-${index}-audio`, zone.content.audioFile);
      if (zone.content?.videoFile) formData.append(`area-${index}-video`, zone.content.videoFile);
      if (zone.content?.textAudioFile) formData.append(`area-${index}-textAudio`, zone.content.textAudioFile);
      if (zone.content?.labelAudioFile) formData.append(`area-${index}-labelAudio`, zone.content.labelAudioFile);
    });

    try {
      const token = localStorage.getItem('token');
      console.log('Token lors de la création:', token);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      };

      if (id) {
        const response = await axios.patch(
          `${API_BASE_URL}/api/blocks/${id}`,
          formData,
          config
        );
        console.log('Réponse update:', response.data);
        alert('Bloc mis à jour avec succès.');
      } else {
        console.log('Données envoyées:', formData);
        const response = await axios.post(
          `${API_BASE_URL}/api/blocks`,
          formData,
          config
        );
        console.log('Réponse création:', response.data);
        alert('Bloc créé avec succès.');
        // Attendre un peu avant la redirection
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      navigate('/');
    } catch (err) {
      console.error('Erreur détaillée:', err);
      
      if (err.response?.data?.error) {
        alert(`Erreur: ${err.response.data.error}`);
      } else if (err.response?.status === 401 || err.response?.status === 403) {
        alert('Accès non autorisé. Veuillez vous connecter en tant que créateur.');
        navigate('/connect');
      } else if (err.response?.status === 404) {
        alert('Erreur : Route non trouvée. Veuillez vérifier la configuration du serveur.');
      } else {
        alert(`Échec de la soumission du bloc: ${err.response?.data?.message || 'Erreur inconnue'}`);
      }
    }
  };

  return (
    <div className="edit-block">
      <div className="header">
        <h2>{id ? 'Modifier le Bloc' : 'Ajouter un Nouveau Bloc'}</h2>
        {id && (
          <button type="button" className="duplicate-button" onClick={handleDuplicate}>
            Dupliquer
          </button>
        )}
      </div>

      <form onSubmit={handleSubmit}>
        {/* Section Titre et Visibilité */}
        <div className="form-section">
          <h3>Titre</h3>
          <div className="form-group">
            <label>
              Titre<span className="required">*</span>
            </label>
            <input
              type="text"
              name="title"
              value={block.title}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group visibility-toggle">
            <label className="toggle-label">
              Visibilité publique
              <button
                type="button"
                className={`toggle-button ${isPublic ? 'active' : ''}`}
                onClick={handlePublicToggle}
              >
                {isPublic ? 'Public' : 'Privé'}
              </button>
            </label>
          </div>
        </div>

        {/* Section Audio du Titre */}
        <div className="form-section">
          <h3>Audio du Titre</h3>
          <div className="form-group">
            {previewTitleAudio || block.titleAudio ? (
              <div className="title-audio-preview">
                <audio controls>
                  <source 
                    src={previewTitleAudio || `${API_BASE_URL}${block.titleAudio}`} 
                    type="audio/mpeg" 
                  />
                  Votre navigateur ne supporte pas l'élément audio.
                </audio>
              </div>
            ) : (
              <p>Aucun audio de titre disponible</p>
            )}
            <label htmlFor="titleAudio">Télécharger un nouvel audio de titre (MP3)</label>
            <input
              type="file"
              id="titleAudio"
              name="titleAudio"
              accept="audio/mpeg"
              onChange={handleTitleAudioChange}
            />
          </div>
        </div>

        {/* Section Numéro du Bloc */}
        <div className="form-section">
          <h3>Numéro du Bloc</h3>
          <div className="form-group">
            <label>
              BlockNumber<span className="required">*</span>
            </label>
            <input
              type="text"
              name="blockNumber"
              value={block.blockNumber}
              onChange={handleChange}
              required
              pattern="\d{5}"
              title="BlockNumber doit être un numéro à 5 chiffres."
              maxLength="5"
            />
          </div>
        </div>

        {/* Section Icône */}
        <div className="form-section">
          <h3>Icône du Bloc</h3>
          <div className="form-group">
            {previewIcon || block.icon ? (
              <div className="icon-preview">
                <img 
                  src={previewIcon || `${API_BASE_URL}${block.icon}`} 
                  alt="Icône du Bloc" 
                  className="preview-icon" 
                />
              </div>
            ) : (
              <p>Aucune icône disponible</p>
            )}
            <label htmlFor="icon">Télécharger une nouvelle icône</label>
            <input
              type="file"
              id="icon"
              name="icon"
              accept="image/*"
              onChange={handleIconChange}
            />
          </div>
        </div>

        <div className="form-section">
  <h3>Couleurs</h3>
  <div className="form-group">
    <label>Couleur de fond principale</label>
    <div className="color-picker-container">
      <input
        type="color"
        id="backgroundColor"
        name="backgroundColor"
        value={block.backgroundColor}
        onChange={handleChange}
        className="color-picker"
      />
      <input
        type="text"
        name="backgroundColor"
        value={block.backgroundColor}
        onChange={handleChange}
        pattern="^#[0-9A-Fa-f]{6}$"
        title="Code couleur hexadécimal (ex: #FF0000)"
        className="color-input"
      />
    </div>
  </div>
  
  <div className="form-group">
    <label>Couleur secondaire</label>
    <div className="color-picker-container">
      <input
        type="color"
        id="secondaryColor"
        name="secondaryColor"
        value={block.secondaryColor}
        onChange={handleChange}
        className="color-picker"
      />
      <input
        type="text"
        name="secondaryColor"
        value={block.secondaryColor}
        onChange={handleChange}
        pattern="^#[0-9A-Fa-f]{6}$"
        title="Code couleur hexadécimal (ex: #FF0000)"
        className="color-input"
      />
    </div>
  </div>
</div>

        {/* Section Catégories */}
        <div className="form-section">
          <h3>Catégories</h3>
          <div className="form-group">
            <label>
              Catégorie 1 (Requise)<span className="required">*</span>
            </label>
            <select
              name="category1"
              value={block.category1}
              onChange={(e) => handleCategoryChange(e, 1)}
              required
            >
              <option value="" disabled>Sélectionnez une catégorie</option>
              {['Automobile', 'Animals', 'Catering', 'Construction', 'Education', 'Emergency', 'Entertainment','Farming', 'Music', 'Nature', 'Performance', 'Religion','Sport', 'Transportation'].map((category) => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Catégorie 2 (Optionnelle)</label>
            <select
              name="category2"
              value={block.category2}
              onChange={(e) => handleCategoryChange(e, 2)}
            >
              <option value="">Aucune</option>
              {['Automobile', 'Animals', 'Catering', 'Construction', 'Education', 'Emergency', 'Entertainment','Farming', 'Music', 'Nature', 'Performance', 'Religion', 'Sport', 'Transportation']
                .filter(category => category !== block.category1)
                .map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
            </select>
          </div>

          <div className="form-group">
            <label>Catégorie 3 (Optionnelle)</label>
            <select
              name="category3"
              value={block.category3}
              onChange={(e) => handleCategoryChange(e, 3)}
            >
              <option value="">Aucune</option>
              {['Automobile', 'Animals', 'Catering', 'Construction', 'Education', 'Emergency', 'Entertainment','Farming', 'Music', 'Nature', 'Performance', 'Religion', 'Sport', 'Transportation']
                .filter(category => 
                  category !== block.category1 && category !== block.category2
                )
                .map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
            </select>
          </div>
        </div>

        {/* Section Langue */}
        <div className="form-section">
          <h3>Langue</h3>
          <div className="form-group">
            <label>Langue</label>
            <select
              name="language"
              value={block.language}
              onChange={handleChange}
            >
              {['FR', 'EN', 'SP', 'DE', 'IT', 'EL', 'PT', 'NL', 'TU', 'RU', 
                'HE', 'AR', 'ZH', 'JP', 'KO', 'HI'].map((lang) => (
                <option key={lang} value={lang}>{lang}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Section Description et médias associés */}
        <div className="form-section">
          <h3>Description</h3>
          <div className="form-group">
            <label>
              Texte de la description<span className="required">*</span>
            </label>
            <textarea
              name="description"
              value={block.description}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            {previewPortrait || block.portrait ? (
              <img
                src={previewPortrait || `${API_BASE_URL}${block.portrait}`}
                alt="Portrait"
                className="preview-portrait"
              />
            ) : (
              <p>Aucun portrait disponible</p>
            )}
            <label htmlFor="portrait">Télécharger un nouveau portrait</label>
            <input
              type="file"
              id="portrait"
              accept="image/*"
              onChange={handlePortraitChange}
            />
          </div>

          <div className="form-group">
            {previewDescriptionlue || block.descriptionlue ? (
              <audio controls>
                <source 
                  src={previewDescriptionlue || `${API_BASE_URL}${block.descriptionlue}`}
                  type="audio/mpeg"
                />
                Votre navigateur ne supporte pas l'élément audio.
              </audio>
            ) : (
              <p>Aucun fichier audio de description disponible</p>
            )}
            <label htmlFor="descriptionlue">
              Télécharger un fichier audio de description (MP3)
            </label>
            <input
              type="file"
              id="descriptionlue"
              accept="audio/mpeg"
              onChange={handleDescriptionlueChange}
            />
          </div>
        </div>

        {/* Section Illustration */}
        <div className="form-section">
          <h3>Illustration</h3>
          <div className="form-group">
            {(previewIllustration || block.illustration) ? (
              <IllustrationEditor
                previewIllustration={
                  previewIllustration || `${API_BASE_URL}${block.illustration}`
                }
                zones={zones}
                setZones={(newZones) => {
                  setZones(newZones);
                  // Mettre à jour aussi block.areas
                  setBlock(prev => ({
                    ...prev,
                    areas: newZones
                  }));
                }}
                handleZoneClick={null}
              />
            ) : (
              <p>Aucune illustration disponible</p>
            )}
            <label htmlFor="illustration">Télécharger une nouvelle illustration</label>
            <input
              type="file"
              id="illustration"
              accept="image/*"
              onChange={handleIllustrationChange}
            />
          </div>
        </div>

        {/* Section PDF */}
        <div className="form-section">
          <h3>PDF</h3>
          <div className="form-group">
            {previewPdf || block.pdf ? (
              <div className="pdf-preview">
                <a 
                  href={previewPdf || `${API_BASE_URL}${block.pdf}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Voir le PDF
                </a>
              </div>
            ) : (
              <p>Aucun fichier PDF disponible</p>
            )}
            <label htmlFor="pdf">Télécharger un nouveau fichier PDF</label>
            <input
              type="file"
              id="pdf"
              accept="application/pdf"
              onChange={handlePdfChange}
            />
          </div>
        </div>

        {/* Section Chanson */}
        <div className="form-section">
          <h3>Chanson</h3>
          <div className="form-group">
            {previewSong || block.song ? (
              songFileType.startsWith('audio/') ? (
                <audio controls>
                  <source 
                    src={previewSong || `${API_BASE_URL}${block.song}`}
                    type={songFileType} 
                  />
                  Votre navigateur ne supporte pas l'élément audio.
                </audio>
              ) : (
                <video controls width="250">
                  <source 
                    src={previewSong || `${API_BASE_URL}${block.song}`}
                    type={songFileType}
                  />
                  Votre navigateur ne supporte pas l'élément vidéo.
                </video>
              )
            ) : (
              <p>Aucune chanson disponible</p>
            )}
            <label htmlFor="song">
              Télécharger un nouveau fichier audio ou vidéo (MP3 ou MP4)
            </label>
            <input
              type="file"
              id="song"
              accept="audio/mpeg,video/mp4"
              onChange={handleSongChange}
            />
          </div>
        </div>

        <button type="submit" className="submit-button">
          {id ? 'Mettre à jour le Bloc' : 'Créer le Bloc'}
        </button>
      </form>
    </div>
  );
}

export default EditBlock;