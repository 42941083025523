// src/contexts/AuthContext.js
import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCreator, setIsCreator] = useState(false);

  const login = (token, role) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    setIsCreator(role === 'creator');
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setIsCreator(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isCreator, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);